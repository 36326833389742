<div class="landing-page">
    <select class="c-input" [value]="homePage" (change)="onChange($event)">
        <option
            *ngFor="let _homePage of homePages | keyvalue : disableSorting"
            [value]="_homePage.key"
            [selected]="homePage === _homePage.key"
            [disabled]="isHomePageOptionDisabled(_homePage.value)"
        >
            {{ _homePage.value }}
        </option>
    </select>
</div>

import { PermissionTypes, Permissions } from './permissions.model';
import { UserContextClaimModel, UserContextModel } from '../usercontext/user-context-http.model';

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PermissionService {

    private readonly featurePermissionClaimType = 'FeaturePermission';

    public buildPermissions(context: UserContextModel): Permissions {
        return {
            canViewAccountX: this.isFeaturePermissionEnabled(context, PermissionTypes.AccountX),
            canViewChinaBondDeals: this.isFeaturePermissionEnabled(context, PermissionTypes.ChinaBondDeals),
            canViewAdminTab: this.isAdminWithinAnyContainer(context),
            canViewFixedIncomeDealMonitor: this.isFeaturePermissionEnabled(context, PermissionTypes.ViewFixedIncomeDealMonitor),
            canViewEquityDealMonitor: this.isFeaturePermissionEnabled(context, PermissionTypes.ViewEquityDealMonitor),
            canViewMunicipalDealMonitor: this.isFeaturePermissionEnabled(context, PermissionTypes.MunicipalDealMonitor),
            hasMunicipalMmdData: this.isFeaturePermissionEnabled(context, PermissionTypes.MmdData),
            canViewMunicipalStreetCalendar: this.isFeaturePermissionEnabled(context, PermissionTypes.MunicipalStreetCalendar),
            canViewDealQuery: this.isFeaturePermissionEnabled(context, PermissionTypes.ViewDealQuery),
            canViewActivityStream: this.isFeaturePermissionEnabled(context, PermissionTypes.ViewActivityStream),
            canViewCompliance: this.isFeaturePermissionEnabled(context, PermissionTypes.ViewCompliance),
            canViewEquityDealQuery: this.isFeaturePermissionEnabled(context, PermissionTypes.ViewEquityDealQuery),
            canViewEquityPublicDeals: this.isFeaturePermissionEnabled(context, PermissionTypes.ViewEquityPublicDeals),
            canViewOrders: this.isFeaturePermissionEnabled(context, PermissionTypes.ViewOrders),
            canViewEquityOrders: this.isFeaturePermissionEnabled(context, PermissionTypes.ViewEquityOrders),
            canViewOrdersForCompanies: this.getCompaniesWithPermission(context, PermissionTypes.OrdersOrionPermission),
            canEditOrdersForCompanies: this.getCompaniesWithEditOrdersPermission(context),
            canViewEquityOrdersForCompanies: this.getCompaniesWithPermission(context, PermissionTypes.EquityOrdersPermission),
            canViewTrancheSizeLimitOrder: this.isFeaturePermissionEnabled(context, PermissionTypes.TrancheSizeLimitOrder),
            canViewAnalytics: this.isFeaturePermissionEnabled(context, PermissionTypes.Analytics),
            primaryOrderManagement: this.isFeaturePermissionEnabled(context, PermissionTypes.PrimaryOrderManagement),
            manualOrdersOnly:  this.checkAllUserCompaniesHaveManualOrderPermission(context),
            pomInternalOrderEntry: this.isPermissionEnabled(context, PermissionTypes.POMInternalOrderEntry),
            pomAggregatedOrderManagement: this.isPermissionEnabled(context, PermissionTypes.POMAggregatedOrderManagement),
            internalDealCreation: this.isPermissionEnabled(context, PermissionTypes.InternalDealCreation),
            thinkFolio: this.isFeaturePermissionEnabled(context, PermissionTypes.ThinkFolio),
            distributionOrders: this.isFeaturePermissionEnabled(context, PermissionTypes.DistributionOrders),
            canViewDistributionOrdersForCompanies: this.getCompaniesWithPermission(context, PermissionTypes.DistributionOrders),
            underwritingOrders: this.isFeaturePermissionEnabled(context, PermissionTypes.UnderwritingOrders),
            canViewUnderwritingOrdersForCompanies: this.getCompaniesWithPermission(context, PermissionTypes.UnderwritingOrders),
            releaseFixAllocations: this.isFeaturePermissionEnabled(context, PermissionTypes.ReleaseFixAllocations),
            internalCommentsAndAlertingOrionPermission: this.isPermissionEnabled(
                context, PermissionTypes.InternalCommentsAndAlertingOrionPermission
            ),
            operationalEntities: this.isFeaturePermissionEnabled(context, PermissionTypes.OperationalEntities),
            companiesWithPOMEnabled: this.getCompaniesWithPermission(context, PermissionTypes.PrimaryOrderManagementOrionPermission),
            companiesWithInternalDealCreatePermission:
                this.getCompaniesWithPermission(context, PermissionTypes.InternalDealCreation),
            softFirmOrders: this.isFeaturePermissionEnabled(context, PermissionTypes.SoftFirmOrders),
            companiesWithTrancheSizeLimitPermission: this.getCompaniesWithPermission(context, PermissionTypes.TrancheSizeLimitOrder),
            companiesWithReleaseFixExecutionsFirm: this.getCompaniesWithPermission(context, PermissionTypes.ReleaseFixExecutionsFirm),
            companiesWithReleaseFixExecutionsFund: this.getCompaniesWithPermission(context, PermissionTypes.ReleaseFixExecutionsFund),
            companiesWithThirdPartyIntegration: this.getVendorOrionIds(context, PermissionTypes.ThirdPartyIntegration),
            companiesWithOperationalEntitiesPermission: this.getCompaniesWithPermission(context, PermissionTypes.OperationalEntities),
            canViewEquityAnalytics: this.isFeaturePermissionEnabled(context, PermissionTypes.EquityAnalytics),
        };
    }

    private isAdminWithinAnyContainer(context: UserContextModel): boolean {
        return context.Profile.Containers.some(container => container && container.IsAdmin);
    }

    private isFeaturePermissionEnabled(context: UserContextModel, permission: PermissionTypes): boolean {
        return context.Claims.some(claim => claim.Type && claim.Type.includes(this.featurePermissionClaimType)
            && claim.Value && claim.Value === permission);
    }

    private isPermissionEnabled(userContext: UserContextModel, permission: PermissionTypes): boolean {
        if (userContext && userContext.Claims) {
            return userContext.Claims.some(claim => {
                return Boolean(
                    claim
                    && claim.Type
                    && this.isClaimMatch(claim, permission)
                    && claim.Value
                );
            });
        }

        return false;
    }

    private isClaimMatch(claim: UserContextClaimModel, featureName: string): boolean {
        const index = claim.Type.lastIndexOf('/') + 1;
        const currentClaimName = claim.Type.substr(index);

        return currentClaimName === featureName;
    }

    private getCompaniesWithPermission(userContext: UserContextModel, permission: PermissionTypes): number[] {
        const companies = userContext.Profile.Companies
            .slice()
            .sort((c1, c2) => c1.Name.localeCompare(c2.Name));

        return companies
            .filter(company => {
                return userContext.Claims.some(claim => claim && claim.Type
                    && claim.Type.indexOf(permission) !== -1
                    && claim.Value === String(company.OrionId));
            })
            .map(company => company.OrionId);
    }

    private getVendorOrionIds(userContext: UserContextModel, permission: PermissionTypes): number[] {
        const claims = userContext.Claims.filter(claim => claim && claim.Type
            && claim.Type.indexOf(permission) !== -1);

        return claims.map(claim => JSON.parse(claim.Value).VendorOrionId);
    }z

    private getCompaniesWithEditOrdersPermission(userContext: UserContextModel): number[] {
        const companiesWithReadonlyOrdersPermission = this.getCompaniesWithPermission(
            userContext, PermissionTypes.OrdersReadOnlyOrionPermission
        );

        return this.getCompaniesWithPermission(userContext, PermissionTypes.OrdersOrionPermission)
            .filter(companyId => !companiesWithReadonlyOrdersPermission.find(
                ordersReadonlyCompanyId => companyId === ordersReadonlyCompanyId
            ));
    }

    private checkAllUserCompaniesHaveManualOrderPermission(userContext: UserContextModel): boolean {
        const companies = userContext.Profile.Companies
            .slice()
            .sort((c1, c2) => c1.Name.localeCompare(c2.Name));

        return companies
            .every(company => {
                    return userContext.Claims.some(claim => claim && claim.Type
                    && claim.Type.indexOf(PermissionTypes.ManualOrdersOnly) !== -1
                    && claim.Value === String(company.OrionId));
            });
    }
}
